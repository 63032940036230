<template>
  <div class="product-specification">
    <div
      class="specification-nutritional"
      v-if="specificationSheet || nutritionalSheet"
    >
      <div v-if="specificationSheet" class="specification">
        <a
          :href="specificationSheet.url"
          :title="specificationSheet.title"
          target="_blank"
          >Specification</a
        >
      </div>
      <div v-if="nutritionalSheet" class="nutritional">
        <a
          :href="nutritionalSheet.url"
          :title="nutritionalSheet.title"
          target="_blank"
          >Nutritional</a
        >
      </div>
    </div>

    <div
      class="ingredient-country"
      v-if="ingredientStatement || countryOfOrigin"
    >
      <div v-if="ingredientStatement" class="ingredient">
        <a
          :href="ingredientStatement.url"
          :title="ingredientStatement.title"
          target="_blank"
          >Ingredient&nbsp;Statement</a
        >
      </div>
      <div v-if="countryOfOrigin" class="country">
        <a
          :href="countryOfOrigin.url"
          :title="countryOfOrigin.title"
          target="_blank"
          >Country&nbsp;of&nbsp;Origin</a
        >
      </div>
    </div>

    <div class="more-information">
      <button @click="$modal.show(modalName)">Click for More Info</button>
    </div>

    <ModalWrapper class="specification-modal" :modal-name="modalName">
      <button class="close-modal-button" @click="$modal.hide(modalName)">
        <span></span>
        <span></span>
      </button>
      <h3 v-html="productSpecification.titleText"></h3>

      <h4 v-if="documentBadges">Documentation</h4>
      <div v-if="documentBadges" class="documentation">
        <div
          v-for="documentBadge in documentBadges"
          :key="documentBadge.id"
          class="document-badge"
        >
          <a
            v-if="documentBadge.document"
            class="badge-button"
            :href="documentBadge.document.url"
            :title="documentBadge.document.title"
            target="_blank"
          >
            <img
              v-if="
                documentBadge.badgeAttachment &&
                  documentBadge.badgeAttachment.image
              "
              :src="documentBadge.badgeAttachment.image[0].url"
              :alt="documentBadge.title"
            />
          </a>
          <a
            v-else-if="documentBadge.documentAttachment"
            class="badge-button"
            :href="documentBadge.documentAttachment.document.url"
            :title="documentBadge.documentAttachment.document.title"
            target="_blank"
          >
            <img :src="documentBadge.image.url" :alt="documentBadge.title" />
          </a>
          <img
            v-else
            :src="documentBadge.image.url"
            :alt="documentBadge.title"
          />
        </div>
      </div>

      <div
        v-if="!productSpecification.sunmetProductReference"
        style="margin-bottom: 2em;"
      >
        <AnchorButtonPrimary
          button-text="Certifications"
          button-link="/certifications"
        />
      </div>

      <div
        class="sunmet-reference"
        v-if="productSpecification.sunmetProductReference"
      >
        <strong>For technical documents please visit our partner:</strong>
        <a href="http://sunmetfruit.com/our-products/" target="_blank">
          <img src="@/assets/products/sunmet-logo.png" alt="" />
        </a>
      </div>

      <h4 v-if="productSpecification.packagingOptions.length">
        Packaging Options
      </h4>
      <div class="packaging-options">
        <div
          v-for="packagingOption in productSpecification.packagingOptions"
          :key="packagingOption.id"
          class="option"
        >
          <p>
            <strong
              >{{ packagingOption.packagingType
              }}<span v-if="packagingOption.quantityDescription">
                -
              </span></strong
            >{{ packagingOption.quantityDescription }}
          </p>
        </div>
        <AnchorButtonPrimary
          button-text="Packaging Details"
          button-link="/facilities-and-packaging"
        />
      </div>
    </ModalWrapper>
  </div>
</template>

<script>
import ModalWrapper from '@/components/ui/ModalWrapper'
import AnchorButtonPrimary from '@/components/ui/AnchorButtonPrimary'
export default {
  name: 'ProductSpecification',
  components: { AnchorButtonPrimary, ModalWrapper },
  props: {
    productSpecification: {
      type: Object,
      required: true
    }
  },
  computed: {
    specificationSheet() {
      return this.productSpecification.specificationSheet &&
        this.productSpecification.specificationSheet.length
        ? this.productSpecification.specificationSheet[0]
        : false
    },
    nutritionalSheet() {
      return this.productSpecification.nutritionalSheet &&
        this.productSpecification.nutritionalSheet.length
        ? this.productSpecification.nutritionalSheet[0]
        : false
    },
    ingredientStatement() {
      return this.productSpecification.ingredientStatement &&
        this.productSpecification.ingredientStatement.length
        ? this.productSpecification.ingredientStatement[0]
        : false
    },
    countryOfOrigin() {
      return this.productSpecification.countryOfOrigin &&
        this.productSpecification.countryOfOrigin.length
        ? this.productSpecification.countryOfOrigin[0]
        : false
    },
    documentBadges() {
      if (
        !this.productSpecification.documentBadges ||
        !this.productSpecification.documentBadges.length
      ) {
        return false
      }

      const clonedDocumentBadges = JSON.parse(
        JSON.stringify(this.productSpecification.documentBadges)
      )

      return clonedDocumentBadges.map(documentBadge =>
        this.mapDocumentBadge(documentBadge)
      )
    },
    modalName() {
      return `product_${this.productSpecification.id}_more_info`
    }
  },
  methods: {
    mapDocumentBadge(documentBadge) {
      documentBadge.badgeAttachment =
        documentBadge.badgeAttachment && documentBadge.badgeAttachment.length
          ? documentBadge.badgeAttachment[0]
          : false

      documentBadge.documentAttachment =
        documentBadge.documentAttachment &&
        documentBadge.documentAttachment.length
          ? documentBadge.documentAttachment[0]
          : false

      if (
        documentBadge.documentAttachment !== false &&
        documentBadge.documentAttachment.document
      ) {
        documentBadge.documentAttachment.document = documentBadge
          .documentAttachment.document.length
          ? documentBadge.documentAttachment.document[0]
          : false
      }

      documentBadge.document =
        documentBadge.document && documentBadge.document.length
          ? documentBadge.document[0]
          : false

      documentBadge.image =
        documentBadge.image && documentBadge.image.length
          ? documentBadge.image[0]
          : false

      return documentBadge
    }
  }
}
</script>

<style lang="scss" scoped>
.product-specification {
  h3 {
    @apply mt-6;
    font-size: 22px;
    letter-spacing: 2px;
  }

  a:not(.badge-button),
  button:not(.close-modal-button) {
    @apply uppercase mx-2 px-5 py-2;
    background-color: #525e6e;
    font-family: 'FuturaPtMedium', serif;
    color: #ffffff;
    letter-spacing: 2px;
    font-size: 14px;

    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .specification-nutritional,
  .ingredient-country,
  .more-information {
    @apply flex justify-center items-center my-5;

    > div {
      @apply w-1/2;

      &:nth-of-type(1) {
        @apply flex justify-end;
      }
      &:nth-of-type(2) {
        @apply flex justify-start;
      }

      &:only-child {
        @apply justify-center;
      }
    }
  }

  .specification-modal {
    h3 {
      @apply pt-12 pb-8;
    }

    h4 {
      @apply uppercase mb-5;
      font-family: 'FuturaPtBook', serif;
      font-size: 22px;
      letter-spacing: 4px;
    }

    .documentation {
      @apply flex flex-wrap justify-evenly items-center w-5/6 mx-auto mb-10;

      .document-badge {
        @apply w-1/4 px-6 py-2;

        img {
          filter: brightness(0) invert(37%) sepia(12%) saturate(745%)
            hue-rotate(175deg) brightness(91%) contrast(87%);
          transition: all 0.3s ease;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .sunmet-reference {
      @apply flex flex-col items-center mb-10 mx-4;

      strong {
        @apply mb-4;
        font-family: 'FuturaPtBook', serif;
        font-size: 28px;
        line-height: 1.2rem;
      }

      a {
        @apply bg-transparent;

        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        img {
          max-width: 300px;
          filter: brightness(0) invert(37%) sepia(12%) saturate(745%)
            hue-rotate(175deg) brightness(91%) contrast(87%);
          transition: all 0.3s ease;
        }
      }
    }

    .packaging-options {
      @apply pb-16;
      .option {
        @apply pb-2;
        p {
          @apply uppercase;
          font-size: 18px;
          letter-spacing: 3px;
          font-family: 'FuturaPtBook', serif;

          strong {
            @apply font-bold;
            font-family: 'FuturaPtBook', serif;
          }
        }
      }
    }
  }

  ////////////////////////////////////////////

  @media screen and (min-width: 600px) {
    h3 {
      @apply mt-6;
      letter-spacing: 2px;
    }

    a:not(.badge-button),
    button:not(.close-modal-button) {
      font-size: 16px;
      letter-spacing: 2px;
    }

    .specification-nutritional,
    .ingredient-country,
    .more-information {
      @apply my-5;
    }

    .specification-modal {
      h4 {
        font-size: 28px;
        letter-spacing: 6px;
      }

      .documentation {
        @apply flex-no-wrap;

        .document-badge {
          @apply w-1/6 px-3 py-4;
        }
      }

      .packaging-options {
        .option {
          p {
            font-size: 22px;
            letter-spacing: 5px;
          }
        }
      }
    }
  }

  ////////////////////////////////////////////

  @media screen and (min-width: 900px) {
    h3 {
      @apply mt-6;
      font-size: 24px;
      letter-spacing: 1px;
    }

    a:not(.badge-button),
    button:not(.close-modal-button) {
      @apply mx-2 px-5;
      font-size: 14px;
      letter-spacing: 1px;
    }

    .specification-nutritional,
    .ingredient-country,
    .more-information {
      @apply my-5;
    }
  }

  ////////////////////////////////////////////

  @media screen and (min-width: 1200px) {
    a:not(.badge-button),
    button:not(.close-modal-button) {
      @apply mx-3 px-10;
      font-size: 16px;
    }

    .specification-nutritional,
    .ingredient-country,
    .more-information {
      @apply my-8;
    }
  }

  ////////////////////////////////////////////

  @media screen and (min-width: 1500px) {
    a:not(.badge-button),
    button:not(.close-modal-button) {
      @apply mx-4;
      letter-spacing: 2px;
    }

    .specification-nutritional,
    .ingredient-country,
    .more-information {
      @apply my-10;
    }
  }
}
</style>
