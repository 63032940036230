<template>
  <div id="productPage">
    <ProductHero
      v-if="productHero"
      @loaded="toggleShowPageTrue"
      :product-hero="productHero"
    />
    <ProductSpecificationsContainer
      v-if="productSpecificationsHeader && productSpecifications"
      :product-specifications-header="productSpecificationsHeader"
      :product-specifications="productSpecifications"
    />
  </div>
</template>

<script>
import api from '@/api'
import ProductHero from '@/components/Hero/ProductHero'
import ProductSpecificationsContainer from '@/components/Product/ProductSpecificationsContainer'
import viewMixin from '@/mixins/viewMixin'
import apiHelper from '@/api/helpers/helpers'

export default {
  name: 'Product',
  components: { ProductHero, ProductSpecificationsContainer },
  mixins: [viewMixin],
  data() {
    return {
      productHero: null,
      productSpecificationsHeader: null,
      productSpecifications: null
    }
  },
  mounted() {
    this.loadProductPage()
  },
  methods: {
    loadProductPage() {
      const slug = this.$route.params.productSlug

      this.setProductData(apiHelper.readStoredResponse(`productPage.${slug}`))

      api.fruitsAndVegetables.loadProductPage(slug).then(response => {
        if (response.entry === null) {
          this.$router.push({ path: '/404' })
        }

        this.setProductData(response)
      })
    },
    setProductData(response) {
      if (!response || !response.entry) return

      this.productHero = response.entry.productHero[0]
      this.productSpecificationsHeader =
        response.entry.productSpecificationsHeader[0]
      this.productSpecifications = response.entry.productSpecifications
    }
  }
}
</script>

<style scoped></style>
