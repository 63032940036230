<template>
  <div class="product-hero-container">
    <div class="container">
      <div class="block">
        <div class="content-section">
          <h1>{{ productHero.titleText }}</h1>
          <p>{{ productHero.description }}</p>
        </div>
        <div class="image-container">
          <h1>{{ productHero.titleText }}</h1>
          <img
            :class="{ 'not-loaded': !this.loaded }"
            :src="productHero.image[0].url"
            :alt="productHero.image[0].title"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductHero',
  props: {
    productHero: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loaded: false
    }
  },
  mounted() {
    this.preloadImage()
  },
  methods: {
    preloadImage() {
      const preloader = document.createElement('img')

      preloader.addEventListener('load', () => {
        this.$emit('loaded')
        this.loaded = true
      })

      preloader.src = this.productHero.image[0].url
    }
  }
}
</script>

<style lang="scss" scoped>
.product-hero-container {
  .container {
    .block {
      @apply w-11/12 flex flex-col-reverse justify-center items-start mx-auto;

      .content-section {
        @apply w-full;
        margin-top: 30px;

        h1 {
          @apply block uppercase text-left;
          display: none;
          font-family: 'FuturaPtBold', serif;
          font-size: 56px;
          letter-spacing: 4px;
        }

        p {
          @apply text-center;
          font-size: 18px;
          letter-spacing: 2px;
        }
      }

      .image-container {
        @apply flex justify-center items-center flex-col w-full;
        margin-top: 60px;

        h1 {
          @apply block uppercase text-center mb-4;
          font-family: 'FuturaPtBold', serif;
          font-size: 36px;
          letter-spacing: 4px;
        }

        img {
          @apply relative w-1/2;
          right: 0;
          transition: all 0.8s linear;

          &.not-loaded {
            right: -300px;
            opacity: 0;
          }
        }
      }
    }
  }

  ///////////////////////////////////////////

  @media screen and (min-width: 600px) {
    .container {
      .block {
        @apply w-5/6;

        .content-section {
          @apply w-5/6 mx-auto;

          p {
            font-size: 22px;
          }
        }

        .image-container {
          margin-top: 70px;

          h1 {
            font-size: 46px;
          }
        }
      }
    }
  }

  ///////////////////////////////////////////

  @media screen and (min-width: 900px) {
    .container {
      .block {
        @apply w-4/5 flex flex-row justify-center items-start mx-auto;

        .content-section {
          @apply w-1/2 pr-16;
          margin-top: 90px;

          h1 {
            @apply block;
          }

          p {
            @apply text-left;
            font-size: 22px;
          }
        }

        .image-container {
          @apply w-1/2;
          margin-top: 70px;

          h1 {
            display: none;
          }

          img {
            @apply w-auto;
          }
        }
      }
    }
  }

  ///////////////////////////////////////////

  @media screen and (min-width: 1200px) {
    .container {
      .block {
        .content-section {
          @apply pr-24;
          margin-top: 130px;

          p {
            font-size: 26px;
          }
        }

        .image-container {
          margin-top: 90px;
        }
      }
    }
  }

  //////////////////////////////////////////////

  @media screen and (min-width: 1500px) {
    .container {
      .block {
        .content-section {
          @apply pr-24;
          margin-top: 160px;

          p {
            font-size: 28px;
          }
        }

        .image-container {
          margin-top: 120px;
          img {
            max-width: 600px;
          }
        }
      }
    }
  }
}
</style>
