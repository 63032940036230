<template>
  <div class="product-specifications-container">
    <div class="product-specifications-grouped">
      <div class="conventional-organic-pickers">
        <button
          :class="{ active: conventionalActive }"
          @click="toggleActiveSection('conventional')"
        >
          Conventional
        </button>
        <button
          :class="{ active: organicActive }"
          @click="toggleActiveSection('organic')"
        >
          Organic
        </button>
      </div>

      <div class="juice-puree-essence-filters">
        <div
          :class="[
            'filter-option',
            { active: jpeSelectedValue === jpeOption.value }
          ]"
          v-for="jpeOption in jpeOptions"
          :key="jpeOption.id"
        >
          <input
            type="radio"
            :id="jpeOption.id"
            name="jpeFilter"
            :value="jpeOption.value"
            v-model="jpeSelectedValue"
          />
          <label :for="jpeOption.id">{{ jpeOption.title }}</label>
        </div>
      </div>

      <TransitionGroup
        class="specifications-lists"
        name="list"
        tag="div"
        move-class="is-moving"
      >
        <strong
          v-if="!visibleProductSpecifications.length"
          :key="activeSectionName + '_' + this.jpeSelected.title"
        >
          {{ activeSectionName + ' ' + this.jpeSelected.title }} options not
          available at this time.
        </strong>

        <Accordion
          class="accordion-item"
          v-for="(specification, index) in visibleProductSpecifications"
          :key="specification.id"
          :style="`z-index: ${100 - index};`"
        >
          <template v-slot:header>
            <div class="container accordion-header">
              <h4 v-html="specification.titleText"></h4>
              <img
                v-if="specification.frozenAseptic === false"
                class="frozen"
                src="@/assets/products/frozen-badge.png"
                alt="Frozen"
              />
              <img
                v-else
                class="aseptic"
                src="@/assets/products/aseptic-badge.png"
                alt="Aseptic"
              />
            </div>
          </template>
          <template v-slot:content>
            <div class="container accordion-content">
              <ProductSpecification
                :key="specification.id"
                :product-specification="specification"
                class="juice-specification"
              />
            </div>
          </template>
        </Accordion>
      </TransitionGroup>
    </div>

    <div
      v-if="productSpecificationsHeader.videoUrl"
      :class="[
        { 'no-video': !productSpecificationsHeader.videoUrl },
        'product-specifications-header'
      ]"
    >
      <div class="container">
        <vueVimeoPlayer
          v-if="productSpecificationsHeader.videoUrl"
          ref="player"
          :class="[
            {
              'opacity-0':
                !productSpecificationsHeader.videoUrl ||
                !productSpecificationsHeader.videoUrl.length
            },
            'vimeo-player'
          ]"
          :video-url="productSpecificationsHeader.videoUrl"
          :video-id="null"
          :options="vimeoOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductSpecification from '@/components/Product/ProductSpecification'
import { vueVimeoPlayer } from 'vue-vimeo-player'
import Accordion from '@/components/ui/Accordion'

export default {
  name: 'ProductSpecificationsContainer',
  components: { Accordion, ProductSpecification, vueVimeoPlayer },
  props: {
    productSpecificationsHeader: {
      type: Object,
      required: true
    },
    productSpecifications: {
      type: Array,
      required: true
    }
  },
  computed: {
    visibleProductSpecifications() {
      return this.productSpecifications.filter(productSpecification => {
        let conOrgFiltered =
          (this.conventionalActive &&
            productSpecification.conventionalOrganic === false) ||
          (this.organicActive &&
            productSpecification.conventionalOrganic === true)

        let jpeFiltered =
          this.jpeSelectedValue === productSpecification.juicePuree

        return conOrgFiltered && jpeFiltered
      })
    },
    activeSectionName() {
      return this.conventionalActive ? 'Conventional' : 'Organic'
    },
    jpeSelected() {
      return this.jpeOptions.find(
        option => option.value === this.jpeSelectedValue
      )
    }
  },
  data() {
    return {
      vimeoOptions: {
        title: false,
        byline: false,
        responsive: true,
        portrait: false,
        autoplay: true,
        muted: true,
        loop: true
      },
      conventionalActive: true,
      organicActive: false,
      jpeOptions: [
        {
          id: 'juice',
          title: 'Juice',
          value: '0'
        },
        {
          id: 'puree',
          title: 'Puree',
          value: '1'
        },
        {
          id: 'essence',
          title: 'Essence',
          value: 'essence'
        }
      ],
      jpeSelectedValue: '0'
    }
  },
  methods: {
    toggleActiveSection(clickedName) {
      this.conventionalActive = clickedName === 'conventional'
      this.organicActive = clickedName === 'organic'
    }
  }
}
</script>

<style lang="scss" scoped>
.product-specifications-container {
  .product-specifications-header {
    @apply bg-contain bg-no-repeat bg-bottom relative;
    z-index: 1;

    .container {
      .vimeo-player {
        @apply w-11/12 mx-auto mt-12 mb-32;
        border: 20px solid #ffffff;
        box-shadow: 0 0 10px rgba(60, 60, 60, 0.4);
      }
    }
  }

  .product-specifications-grouped {
    @apply relative;

    .conventional-organic-pickers {
      @apply flex justify-center relative;
      z-index: 0;

      button {
        @apply w-1/2 uppercase pt-12 pb-12;
        font-size: 18px;
        letter-spacing: 2px;
        transition: all 0.5s ease;
        outline: none;

        &:not(.active) {
          background-color: #f0f2f2;
          &:hover {
            background-color: #e2e2e2;
          }

          &:nth-of-type(1) {
            box-shadow: inset -8px -8px 15px rgba(60, 60, 60, 0.5);
          }
          &:nth-of-type(2) {
            box-shadow: inset 8px -8px 15px rgba(60, 60, 60, 0.5);
          }
        }
      }
    }

    .juice-puree-essence-filters {
      @apply flex justify-evenly py-6;

      .filter-option {
        @apply cursor-pointer relative;
        transition: all 0.5s ease;
        border-radius: 50px;
        box-shadow: 3px 3px 12px #adadad;

        &:not(.active):hover {
          background-color: rgba(82, 94, 110, 0.3);
          box-shadow: 3px 3px 8px #adadad;
        }

        &.active {
          background-color: #525e6e;
          color: #ffffff;
        }

        &:not(.active) {
          background-color: #d8dadd;
        }

        label {
          @apply w-full block cursor-pointer px-6 py-4;
          font-size: 16px;
          letter-spacing: 2px;
        }
        input {
          @apply hidden;
        }
      }
    }

    .specifications-lists {
      @apply pb-24 w-full overflow-x-hidden;

      .list-enter-active,
      .list-leave-active {
        transition: all 0.5s ease;
      }

      .list-leave-active {
        @for $i from 0 through 9 {
          &:nth-of-type(#{$i + 1}) {
            transition-delay: #{$i / 10}s;
          }
        }
      }

      .list-enter-active {
        @apply opacity-0;
        @for $i from 0 through 9 {
          &:nth-of-type(#{$i + 1}) {
            transition-delay: #{($i / 10) + 0.3}s;
          }
        }
        transform: translateX(-100%);
      }

      .list-leave-to {
        opacity: 0;
        transform: translateX(100%);
      }

      .list-enter-to {
        opacity: 1;
        transform: translateX(0);
        transition-delay: 0s;
      }

      strong {
        @apply inline-block pt-16;
        font-size: 24px;
        letter-spacing: 1px;
      }

      .accordion-item {
        box-shadow: 0 5px 5px rgba(44, 68, 67, 0.7);

        .accordion-header {
          @apply relative flex justify-center items-center bg-no-repeat;
          transition: all 0.6s ease;
          background-image: linear-gradient(180deg, #efefefff, transparent);
          background-position: 0 -100px;

          &:hover {
            background-position: 0 0;
          }

          h4 {
            @apply text-center py-8;
            font-size: 22px;
          }
          img {
            @apply ml-6;
            max-height: 36px;
            &.frozen {
              transform: rotate(14deg);
            }

            &.aseptic {
              transform: rotate(-14deg);
            }
          }
        }

        .accordion-content {
          @apply flex justify-center;

          .product-specification {
            @apply w-full;
          }
        }
      }
    }
  }

  /////////////////////////////////////////

  @media screen and (min-width: 600px) {
    .product-specifications-header {
      .container {
        .vimeo-player {
          @apply w-5/6;
        }
      }
    }

    .product-specifications-grouped {
      .juice-puree-essence-filters {
        @apply py-6;

        .filter-option {
          label {
            @apply px-10 py-4;
            font-size: 18px;
          }
        }
      }

      .conventional-organic-pickers {
        button {
          font-size: 32px;
        }
      }

      .specifications-lists {
        .accordion-item {
          .accordion-header {
            h4 {
              @apply py-8;
              font-size: 26px;
            }
            img {
              max-height: 42px;
            }
          }
        }
      }
    }
  }

  /////////////////////////////////////////

  @media screen and (min-width: 900px) {
    .product-specifications-header {
      .container {
        .vimeo-player {
          @apply w-4/5;
        }
      }
    }

    .product-specifications-grouped {
      .juice-puree-essence-filters {
        @apply py-6;

        .filter-option {
          label {
            @apply px-16 py-6;
            font-size: 26px;
          }
        }
      }

      .conventional-organic-pickers {
        button {
          @apply pt-16 pb-16;
          font-size: 46px;
        }
      }

      .specifications-lists {
        .accordion-item {
          .accordion-header {
            h4 {
              @apply py-8;
              font-size: 38px;
            }
            img {
              max-height: 60px;
            }
          }
        }
      }
    }
  }

  /////////////////////////////////////////

  @media screen and (min-width: 1200px) {
  }

  /////////////////////////////////////////

  @media screen and (min-width: 1500px) {
  }
}
</style>
