var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-specifications-container"},[_c('div',{staticClass:"product-specifications-grouped"},[_c('div',{staticClass:"conventional-organic-pickers"},[_c('button',{class:{ active: _vm.conventionalActive },on:{"click":function($event){return _vm.toggleActiveSection('conventional')}}},[_vm._v(" Conventional ")]),_c('button',{class:{ active: _vm.organicActive },on:{"click":function($event){return _vm.toggleActiveSection('organic')}}},[_vm._v(" Organic ")])]),_c('div',{staticClass:"juice-puree-essence-filters"},_vm._l((_vm.jpeOptions),function(jpeOption){return _c('div',{key:jpeOption.id,class:[
          'filter-option',
          { active: _vm.jpeSelectedValue === jpeOption.value }
        ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.jpeSelectedValue),expression:"jpeSelectedValue"}],attrs:{"type":"radio","id":jpeOption.id,"name":"jpeFilter"},domProps:{"value":jpeOption.value,"checked":_vm._q(_vm.jpeSelectedValue,jpeOption.value)},on:{"change":function($event){_vm.jpeSelectedValue=jpeOption.value}}}),_c('label',{attrs:{"for":jpeOption.id}},[_vm._v(_vm._s(jpeOption.title))])])}),0),_c('TransitionGroup',{staticClass:"specifications-lists",attrs:{"name":"list","tag":"div","move-class":"is-moving"}},[(!_vm.visibleProductSpecifications.length)?_c('strong',{key:_vm.activeSectionName + '_' + this.jpeSelected.title},[_vm._v(" "+_vm._s(_vm.activeSectionName + ' ' + this.jpeSelected.title)+" options not available at this time. ")]):_vm._e(),_vm._l((_vm.visibleProductSpecifications),function(specification,index){return _c('Accordion',{key:specification.id,staticClass:"accordion-item",style:(("z-index: " + (100 - index) + ";")),scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"container accordion-header"},[_c('h4',{domProps:{"innerHTML":_vm._s(specification.titleText)}}),(specification.frozenAseptic === false)?_c('img',{staticClass:"frozen",attrs:{"src":require("@/assets/products/frozen-badge.png"),"alt":"Frozen"}}):_c('img',{staticClass:"aseptic",attrs:{"src":require("@/assets/products/aseptic-badge.png"),"alt":"Aseptic"}})])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"container accordion-content"},[_c('ProductSpecification',{key:specification.id,staticClass:"juice-specification",attrs:{"product-specification":specification}})],1)]},proxy:true}],null,true)})})],2)],1),(_vm.productSpecificationsHeader.videoUrl)?_c('div',{class:[
      { 'no-video': !_vm.productSpecificationsHeader.videoUrl },
      'product-specifications-header'
    ]},[_c('div',{staticClass:"container"},[(_vm.productSpecificationsHeader.videoUrl)?_c('vueVimeoPlayer',{ref:"player",class:[
          {
            'opacity-0':
              !_vm.productSpecificationsHeader.videoUrl ||
              !_vm.productSpecificationsHeader.videoUrl.length
          },
          'vimeo-player'
        ],attrs:{"video-url":_vm.productSpecificationsHeader.videoUrl,"video-id":null,"options":_vm.vimeoOptions}}):_vm._e()],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }